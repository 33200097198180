import ipc from '@/utils/ipc'

export default async function({ app, $axios, store, redirect }) {
  let port

  if (process.env.platform === 'electron') {
    port = await ipc('getPort')

    store.commit('network/setPort', port)
  }

  await store.dispatch('config/getConfig')

  if (store.getters['config/type'] === 'shared') {
    $axios.defaults.baseURL = `http://localhost:${port}`
  } else {
    $axios.defaults.baseURL = store.getters['network/onLine']
      ? process.env.baseUrl
      : `http://localhost:${port}`
  }

  $axios.onRequest(req => {
    if (req.method === 'post') {
      if (!req.data) {
        req.data = {}
      }

      const timezone = new Date()
        .toString()
        .split('GMT')[1]
        .slice(0, 5)

      req.data.meta = {
        timezone: `${timezone.substr(0, 3)}:${timezone.substr(3, 2)}`,
        language: app.i18n.localeProperties.iso
      }
    }
  })

  $axios.onResponse(response => {
    if (response.data && response.data._app) {
      if (response.data._app.updateRequired) {
        redirect(app.localePath('/update-required'))
      }

      store.commit(
        'config/setUpdateAvailable',
        response.data._app.updateAvailable
      )
    }
  })

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    if (code === 401) {
      store.dispatch('user/logout')
    }

    if (code === 500) {
      const errorName = error.response.data.name
      switch (errorName) {
        case 'AdminUserRequiredError':
        case 'DbPathRequiredError':
          redirect(app.localePath('/config'))
          break
        case 'ContractAcceptanceRequiredError':
          redirect(app.localePath('/accept-contract'))
          break
        case 'LicenseRequiredError':
        case 'InvalidLicenseError':
          redirect(app.localePath('/settings/licenses'))
          break
      }
    }
  })
}
